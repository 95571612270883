import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import locale from "../helpers/values.json";
export default function SEO(props: {
    title?: string;
    description?: string;
    blog: boolean;
    schema?: any;
}) {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);
    const { defaultTitle, defaultDescription, siteUrl } = site.siteMetadata;
    const langExtracted = pathname.split("/")[1];
    let lang = "en";
    if (langExtracted && locale.supported_languages.includes(langExtracted)) {
        lang = langExtracted;
    }
    const seo = {
        title: props.title || defaultTitle,
        description: props.description || defaultDescription,
        url: `${siteUrl}${pathname}`,
    };
    let schema1 = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [
            {
                "@type": "Question",
                name: "When can I register for the (ISC)² CISSP online proctoring pilot test?",
                acceptedAnswer: {
                    "@type": "Answer",
                    text: "You can register for the (ISC)² CISSP online proctoring pilot test beginning February 14, 2022 to register Registration is limited to first-come, first-served and will close February 21, 2022.",
                },
            },
            {
                "@type": "Question",
                name: "When will the (ISC)² CISSP online proctoring pilot tests occur?",
                acceptedAnswer: {
                    "@type": "Answer",
                    text: "February 28 – March 7, 2022. ",
                },
            },
            {
                "@type": "Question",
                name: "Can I take CISSP online proctoring pilot test?",
                acceptedAnswer: {
                    "@type": "Answer",
                    text: "Online proctoring pilot test deliveries will be limited to candidates in the United States, United Kingdom and Singapore. if you are residing in any of this countries contact us now.",
                },
            },
            {
                "@type": "Question",
                name: "How much it cost to take CISSP Online Exam?",
                acceptedAnswer: { "@type": "Answer", text: "749USD" },
            },
            {
                "@type": "Question",
                name: "In what format will certification examinations be available as part of the pilot test?",
                acceptedAnswer: {
                    "@type": "Answer",
                    text: "Computerized Adaptive Test (CAT) version. Candidates will have up to three hours to complete the examination, which includes 100-150 multiple choice items.",
                },
            },
            {
                "@type": "Question",
                name: "Is the online exam the same as the test center-delivered exams?",
                acceptedAnswer: {
                    "@type": "Answer",
                    text: "Examinations delivered online are equivalent to test center-delivered examinations with regard to examination outlines (domains and weighting), item types and difficulty.",
                },
            },
        ],
    };

    if (props.schema) {
        try {
            schema1 = JSON.parse(props.schema);
        } catch (e) {
            console.error(e);
        }
    }
    const schema2 = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "CISSP Exam Online",
        alternateName: "CISSP Online Pilot Exam",
        url: "http://cisspexamonline.com/",
    };
    return (
        <Helmet
            title={seo.title}
            htmlAttributes={{
                lang
            }}
        >
            <meta name="description" content={seo.description} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {props.blog && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            <meta name="geo.region" content="US" />
            <meta name="robots" content="index, follow" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="7 days" />
            <meta charSet="UTF-8" />
            <meta
                name="keywords"
                content="CISSP Exam Online, CISSP Exam Online in 2024, CISSP Exam Online From Home, CISSP Exam Singapore, CISSP Exam USA, CISSP Exam United Kingdom"
            />
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-33JWX332EW"></script>
      <script type="text/javascript">
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-33JWX332EW');`}
      </script>
            <script type="application/ld+json">
                {JSON.stringify(schema1)}
            </script>

            {!props.schema && (
                <script type="application/ld+json">
                    {JSON.stringify(schema2)}
                </script>
            )}
        </Helmet>
    );
}
const query = graphql`
    query {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
                siteUrl
            }
        }
    }
`;
